define("discourse/plugins/discourse-ai/discourse/connectors/discovery-above/mobile-gist-toggle", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/ai-gist-toggle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _aiGistToggle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicGistToggle extends _component.default {
    static shouldRender(outletArgs1, helper1) {
      const isMobileView1 = helper1.site.mobileView;
      return isMobileView1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AiGistToggle />
      
    */
    {
      "id": "48tnVA9k",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/discovery-above/mobile-gist-toggle.js",
      "scope": () => [_aiGistToggle.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicGistToggle;
});